import React, { useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ChevronDown, ChevronUp, Csv } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { MortaSyncTypes } from 'types'
import { updateTable } from 'components/spreadsheet/contexts/data/actions'
import Select from 'components/select'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import Button from 'components/button'
import api from 'helpers/api'
import Flag from 'components/flag'
import constants from 'style/constants.module.scss'

interface ImportMenuProps extends MenuProps {
  setAppendCSVModal: (value: boolean) => void
  setViewpointModal: (value: boolean) => void
  setViewpointRfisModal: (value: boolean) => void
  setBIM360Modal: (value: boolean) => void
  setBIM360ChecklistsModal: (value: boolean) => void
  setBIM360IssuesModal: (value: boolean) => void
  setBIM360ModelsModal: (value: boolean) => void
  setProcoreModal: (value: boolean) => void
  setAconexModal: (value: boolean) => void
  setAconexWorkflowsModal: (value: boolean) => void
  setMortaModal: (value: boolean, type: MortaSyncTypes) => void
  setReviztoModal: (value: boolean) => void
  setAsiteDocumentsModal: (value: boolean) => void
}

const ImportMenu: React.FC<ImportMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setAppendCSVModal,
  setViewpointModal,
  setViewpointRfisModal,
  setBIM360Modal,
  setBIM360ChecklistsModal,
  setBIM360IssuesModal,
  setBIM360ModelsModal,
  setProcoreModal,
  setAconexModal,
  setAconexWorkflowsModal,
  setMortaModal,
  setReviztoModal,
  setAsiteDocumentsModal
}) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const isAutodeskSynced =
    spreadsheetData.tableDetails.isAutodeskBim360Synced ||
    spreadsheetData.tableDetails.isAutodeskBim360ChecklistsSynced ||
    spreadsheetData.tableDetails.isAutodeskBim360IssuesSynced ||
    spreadsheetData.tableDetails.isAutodeskBim360ModelsSynced
  const isViewpointSynced =
    spreadsheetData.tableDetails.isViewpointSynced || spreadsheetData.tableDetails.isViewpointRfisSynced
  const isAsiteSynced = spreadsheetData.tableDetails.isAsiteDocumentsSynced
  const isProcoreSynced = spreadsheetData.tableDetails.isProcoreSynced
  const isAconexSynced =
    spreadsheetData.tableDetails.isAconexSynced || spreadsheetData.tableDetails.isAconexWorkflowsSynced
  const isMortaSynced =
    spreadsheetData.tableDetails.isMortaProjectsSynced ||
    spreadsheetData.tableDetails.isMortaUsersSynced ||
    spreadsheetData.tableDetails.isMortaResourcesSynced ||
    spreadsheetData.tableDetails.isMortaColumnsSynced ||
    spreadsheetData.tableDetails.isMortaCommentsSynced
  const isReviztoSynced = spreadsheetData.tableDetails.isReviztoIssuesSynced

  const [autodeskExpanded, setAutodeskExpanded] = useState<boolean>(isAutodeskSynced)
  const [viewpointExpanded, setViewpointExpanded] = useState<boolean>(isViewpointSynced)
  const [asiteExpanded, setAsiteExpanded] = useState<boolean>(isAsiteSynced)
  const [procoreExpanded, setProcoreExpanded] = useState<boolean>(isProcoreSynced)
  const [aconexExpanded, setAconexExpanded] = useState<boolean>(isAconexSynced)
  const [mortaExpanded, setMortaExpanded] = useState<boolean>(isMortaSynced)
  const [reviztoExpanded, setReviztoExpanded] = useState<boolean>(isReviztoSynced)

  const handleManualRetrySync = () => {
    if (!spreadsheetData.tableDetails.isSynced) {
      setSnackbarMessage({ message: 'Table is not synced', status: 'error' })
    } else {
      api
        .manualSyncIntegration(spreadsheetData.tableDetails.publicId)
        .then(() => {
          setSnackbarMessage({
            message: 'Sync retried successfully. You will receive an email shortly.',
            status: 'success'
          })
          setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'isSyncing', value: true })
          const interval = setInterval(() => {
            api.getSyncIntegrationInfo(spreadsheetData.tableDetails.publicId).then((response) => {
              if (response && response.data && response.data.isSyncing === false) {
                setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'isSyncing', value: false })
                setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'lastSync', value: response.data.lastSync })
                setSpreadsheetData({
                  type: 'UPDATE_TABLE',
                  field: 'failedSyncAttempts',
                  value: response.data.failedSyncAttempts
                })
                clearInterval(interval)
              }
            })
          }, 3000)
        })
        .catch((e) => {
          displayErrorMessage(e)
        })
    }
  }

  const anyExpanded =
    autodeskExpanded ||
    viewpointExpanded ||
    asiteExpanded ||
    procoreExpanded ||
    aconexExpanded ||
    mortaExpanded ||
    reviztoExpanded

  const Active: React.FC<{ active: boolean }> = ({ active }) => (
    <span
      className={`bg-${active ? 'green' : 'red'} rounded font-bold text-sm ml-auto`}
      style={{ padding: '5px', color: 'white', fontSize: '90%' }}
    >
      {active ? 'Active' : 'Inactive'}
    </span>
  )

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div className="overflow-y-auto" style={{ maxHeight: '500px', listStyle: 'none', padding: '0px' }}>
        {!spreadsheetData.viewDetails.disableNewRow && (
          <div>
            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              File Upload
            </li>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor: spreadsheetData.tableDetails.isSynced ? 'not-allowed' : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced) {
                    return
                  }
                  setAppendCSVModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <Csv />
                <span className="ml-10px">CSV File</span>
              </div>
            </li>
          </div>
        )}

        {spreadsheetData.isAdmin && (
          <div>
            {spreadsheetData.tableDetails.isSynced && (
              <div>
                <li
                  className="font-bold bg-light-grey"
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  External Sync Settings
                </li>

                <li style={{ marginTop: '5px' }}>
                  <div
                    className="relative flex items-center rounded text-primary select-none"
                    style={{
                      padding: '0 10px',
                      margin: '10px',
                      height: '32px',
                      lineHeight: '32px'
                    }}
                  >
                    <span>Last Successful Sync:</span>
                    <div className="ml-auto">
                      {spreadsheetData.tableDetails.isSynced && spreadsheetData.tableDetails.lastSync
                        ? new Date(spreadsheetData.tableDetails.lastSync + 'Z').toLocaleString()
                        : 'No Sync Configured'}
                    </div>
                  </div>
                </li>

                {spreadsheetData.tableDetails.failedSyncAttempts > 0 && (
                  <div>
                    <li style={{ marginTop: '10px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none"
                        style={{
                          padding: '0 10px',
                          margin: '10px',
                          height: '32px',
                          lineHeight: '32px'
                        }}
                      >
                        <span style={{ marginRight: '5px' }}>
                          <b className="text-red">{spreadsheetData.tableDetails.failedSyncAttempts} Failed</b> Sync
                          Attempts
                        </span>
                      </div>
                    </li>
                  </div>
                )}

                {spreadsheetData.isAdmin && (
                  <li style={{ marginTop: '5px' }}>
                    <div
                      className="relative flex items-center rounded text-primary select-none"
                      style={{
                        padding: '0 10px',
                        margin: '10px'
                      }}
                    >
                      <span>Auto Refresh:</span>
                      <div style={{ marginLeft: 'auto', width: '200px' }}>
                        <Select
                          options={[
                            { label: 'Never', value: '0' },
                            { label: 'Every Day (Midnight)', value: '24' }
                          ]}
                          onOptionClick={(option) => {
                            updateTable(
                              'syncHourlyFrequency',
                              spreadsheetData,
                              setSpreadsheetData,
                              Number.parseInt(option),
                              project.publicId,
                              () =>
                                setSnackbarMessage({
                                  message: 'Sync frequency updated successfully',
                                  status: 'success'
                                }),
                              (error) => displayErrorMessage(error)
                            )
                          }}
                          optionsSelected={[spreadsheetData.tableDetails.syncHourlyFrequency.toString()]}
                        />
                      </div>
                    </div>
                  </li>
                )}

                <div>
                  <li style={{ marginTop: '10px' }}>
                    <div
                      className="relative flex items-center rounded text-primary select-none"
                      style={{
                        padding: '0 10px',
                        margin: '10px',
                        height: '32px',
                        lineHeight: '32px'
                      }}
                    >
                      <span style={{ marginRight: '5px' }}>Manual Refresh:</span>
                      <Button
                        internalType="outline"
                        onClick={() => {
                          handleManualRetrySync()
                        }}
                        style={{ marginLeft: 'auto', width: '200px' }}
                        isLoading={spreadsheetData.tableDetails.isSyncing}
                      >
                        Sync Now
                      </Button>
                    </div>
                  </li>
                </div>
              </div>
            )}

            {spreadsheetData.isAdmin && (
              <div>
                <li
                  className="flex items-center font-bold bg-light-grey"
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  External Data Sources
                  <Button
                    internalType={anyExpanded ? 'danger' : 'accept'}
                    style={{ marginLeft: 'auto', transition: 'all 0s', fontSize: '90%' }}
                    onClick={() => {
                      if (anyExpanded) {
                        setAutodeskExpanded(false)
                        setViewpointExpanded(false)
                        setAsiteExpanded(false)
                        setProcoreExpanded(false)
                        setAconexExpanded(false)
                        setMortaExpanded(false)
                        setReviztoExpanded(false)
                      } else {
                        setAutodeskExpanded(true)
                        setViewpointExpanded(true)
                        setAsiteExpanded(true)
                        setProcoreExpanded(true)
                        setAconexExpanded(true)
                        setMortaExpanded(true)
                        setReviztoExpanded(true)
                      }
                    }}
                  >
                    {anyExpanded ? 'Collapse All' : 'Expand All'}
                  </Button>
                </li>
                <li
                  className={`flex items-center ${autodeskExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/autodesk-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Autodesk icon"
                  />
                  <span className="ml-10px">ACC/BIM 360</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setAutodeskExpanded(!autodeskExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {autodeskExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {autodeskExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360Synced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360Synced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setBIM360Modal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/autodesk-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Autodesk icon"
                        />
                        <span className="ml-10px">Documents</span>
                        <Active active={spreadsheetData.tableDetails.isAutodeskBim360Synced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360ChecklistsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360ChecklistsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setBIM360ChecklistsModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/autodesk-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Autodesk icon"
                        />
                        <span className="ml-10px">Checklists</span>
                        <Active active={spreadsheetData.tableDetails.isAutodeskBim360ChecklistsSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360IssuesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360IssuesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setBIM360IssuesModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/autodesk-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Autodesk icon"
                        />
                        <span className="ml-10px">Issues</span>
                        <Active active={spreadsheetData.tableDetails.isAutodeskBim360IssuesSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360ModelsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAutodeskBim360ModelsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setBIM360ModelsModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/autodesk-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Autodesk icon"
                        />
                        <span className="flex ml-10px">
                          AEC Data Model
                          <Flag text="Enterprise" color={constants.blue} />
                        </span>
                        <Active active={spreadsheetData.tableDetails.isAutodeskBim360ModelsSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${viewpointExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/viewpoint-icon.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Viewpoint For Projects icon"
                  />
                  <span className="ml-10px">Viewpoint For Projects</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setViewpointExpanded(!viewpointExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {viewpointExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {viewpointExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isViewpointSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isViewpointSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setViewpointModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/viewpoint-icon.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Viewpoint For Projects icon"
                        />
                        <span className="ml-10px">Documents</span>
                        <Active active={spreadsheetData.tableDetails.isViewpointSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isViewpointRfisSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isViewpointRfisSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setViewpointRfisModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/viewpoint-icon.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Viewpoint For Projects icon"
                        />
                        <span className="ml-10px">Tasks</span>
                        <Active active={spreadsheetData.tableDetails.isViewpointRfisSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${asiteExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/asite-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Asite icon"
                  />
                  <span className="ml-10px">Asite</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setAsiteExpanded(!asiteExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {asiteExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {asiteExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAsiteDocumentsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAsiteDocumentsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setAsiteDocumentsModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/asite-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Asite icon"
                        />
                        <span className="ml-10px">Documents</span>
                        <Active active={spreadsheetData.tableDetails.isAsiteDocumentsSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${aconexExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/aconex-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Aconex icon"
                  />
                  <span className="ml-10px">Aconex</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setAconexExpanded(!aconexExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {aconexExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {aconexExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setAconexModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/aconex-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Aconex icon"
                        />
                        <span className="ml-10px">Documents</span>
                        <Active active={spreadsheetData.tableDetails.isAconexSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAconexWorkflowsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isAconexWorkflowsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setAconexWorkflowsModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/aconex-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Aconex icon"
                        />
                        <span className="ml-10px">Workflows</span>
                        <Active active={spreadsheetData.tableDetails.isAconexWorkflowsSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${procoreExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/procore-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Procore icon"
                  />
                  <span className="ml-10px">Procore</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setProcoreExpanded(!procoreExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {procoreExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {procoreExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isProcoreSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isProcoreSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setProcoreModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/procore-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Procore icon"
                        />
                        <span className="ml-10px">Documents</span>
                        <Active active={spreadsheetData.tableDetails.isProcoreSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${reviztoExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/revizto-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Revizto icon"
                  />
                  <span className="ml-10px">Revizto</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setReviztoExpanded(!reviztoExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {reviztoExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {reviztoExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isReviztoIssuesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isReviztoIssuesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setReviztoModal(true)
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/revizto-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Revizto icon"
                        />
                        <span className="ml-10px">Issues</span>
                        <Active active={spreadsheetData.tableDetails.isReviztoIssuesSynced} />
                      </div>
                    </li>
                  </div>
                )}

                <li
                  className={`flex items-center ${mortaExpanded ? 'bg-grey' : ''}`}
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <img
                    src="/assets/images/morta-small.png"
                    style={{ width: '16px', height: '16px' }}
                    alt="Morta icon"
                  />
                  <span className="ml-10px">Morta</span>
                  <span
                    className="hover-bg-light-grey cursor-pointer"
                    onClick={() => setMortaExpanded(!mortaExpanded)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {mortaExpanded ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </li>

                {mortaExpanded && (
                  <div className="bg-light-grey" style={{ padding: '10px 0px' }}>
                    <li>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaProjectsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaProjectsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setMortaModal(true, 'Projects')
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/morta-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Morta icon"
                        />
                        <span className="ml-10px">Hubs</span>
                        <Active active={spreadsheetData.tableDetails.isMortaProjectsSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaUsersSynced
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            spreadsheetData.tableDetails.isSynced &&
                            !spreadsheetData.tableDetails.isMortaUsersSynced
                          ) {
                            return
                          }
                          setMortaModal(true, 'Users')
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/morta-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Morta icon"
                        />
                        <span className="ml-10px">Members</span>
                        <Active active={spreadsheetData.tableDetails.isMortaUsersSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaResourcesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaResourcesSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setMortaModal(true, 'Resources')
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/morta-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Morta icon"
                        />
                        <span className="ml-10px">Resources</span>
                        <Active active={spreadsheetData.tableDetails.isMortaResourcesSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaColumnsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaColumnsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setMortaModal(true, 'Columns')
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/morta-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Morta icon"
                        />
                        <span className="ml-10px">Columns</span>
                        <Active active={spreadsheetData.tableDetails.isMortaColumnsSynced} />
                      </div>
                    </li>

                    <li style={{ marginTop: '5px' }}>
                      <div
                        className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                        style={{
                          padding: '0 10px',
                          margin: '0px 10px',
                          height: '32px',
                          lineHeight: '32px',
                          cursor:
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaCommentsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (
                            (spreadsheetData.tableDetails.isSynced &&
                              !spreadsheetData.tableDetails.isMortaCommentsSynced) ||
                            spreadsheetData.tableDetails.isSyncing
                          ) {
                            return
                          }
                          setMortaModal(true, 'Comments')
                          setMenuState(INITIAL_CONTEXT_MENU_STATE)
                        }}
                      >
                        <img
                          src="/assets/images/morta-small.png"
                          style={{ width: '16px', height: '16px' }}
                          alt="Morta icon"
                        />
                        <span className="ml-10px">Comments</span>
                        <Active active={spreadsheetData.tableDetails.isMortaCommentsSynced} />
                      </div>
                    </li>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(ImportMenu)
