import React, { useState, useEffect } from 'react'
import Button from 'components/button'
import api from 'helpers/api'
import { isValidEmail } from 'helpers/utils'
import useProject from 'hooks/project'
import { useApplicationStore } from 'hooks/application'
import { ITableWithCells, IMemberInvite, OptionProps } from 'types'
import Select from 'components/select'
import { PROJECT, ProjectRoles } from 'app-constants'

interface Props {
  invite?: IMemberInvite
  handleOnSuccess: () => void
}

const createSelectedOptions = (invite: IMemberInvite, tagTablesWithCells: ITableWithCells[]) => {
  const options: Record<string, OptionProps[]> = {}

  for (const tag of invite.tags) {
    for (const table of tagTablesWithCells) {
      for (const cell of table.cells) {
        if (cell.id === tag.referencePublicId) {
          if (options[table.publicId]) {
            options[table.publicId].push({
              value: cell.id,
              label: cell.value,
              group: cell.column.name
            })
          } else {
            options[table.publicId] = [
              {
                value: cell.id,
                label: cell.value,
                group: cell.column.name
              }
            ]
          }
        }
      }
    }
  }

  return options
}

const InviteUserForm: React.FC<Props> = ({ invite, handleOnSuccess }: Props) => {
  const [tagTablesWithCells, setTagTablesWithCells] = useState<ITableWithCells[]>([])
  const [projectRole, setProjectRole] = useState<string>(invite ? invite.projectRole : ProjectRoles.MEMBER)
  const [userEmails, setUserEmails] = useState<string[]>(invite ? [invite.email] : [''])
  const [selectedTags, setSelectedTags] = useState<Record<string, OptionProps[]>>(
    invite ? createSelectedOptions(invite, tagTablesWithCells) : {}
  )
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const handleClick = async () => {
    for (let i = 0; i < userEmails.length; i++) {
      const email = userEmails[i].trim()
      if (!isValidEmail(email)) {
        setSnackbarMessage({ status: 'error', message: 'A user email is not in the correct format.' })
        return
      }
    }

    try {
      let tags: string[] = []
      Object.entries(selectedTags).forEach(([, value]) => {
        tags = tags.concat(value.map((option) => (option.value !== undefined ? option.value : '')))
      })

      if (invite) {
        await api.updateInvite(invite, { projectId: project.publicId, tags, projectRole })
      } else {
        await api.createInvite({
          projectId: project.publicId,
          emails: userEmails.map((email) => email.trim()),
          tags,
          projectRole
        })
      }

      handleOnSuccess()
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const fetchTagTables = async () => {
    try {
      const response = await api.getProjectTags(project.publicId)
      setTagTablesWithCells(response.data)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  useEffect(() => {
    fetchTagTables()
  }, [])

  const capitaliseFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  return (
    <div>
      <div className="mb-8">
        <p style={{ marginBottom: '20px' }}>
          If the user has an account on Morta, they will be added to the {PROJECT} immediately, otherwise we will send
          them an invite email with instructions on how to sign up.
        </p>
        <input
          placeholder="Invited user emails (comma separated)"
          required
          value={userEmails.join(',')}
          onChange={(event) => {
            const emails = event.target.value.split(',')
            setUserEmails(emails)
          }}
          disabled={invite ? true : false}
        />
      </div>

      <div className="mt-4 mb-8">
        <h3 className="mt-20px mb-20px">{capitaliseFirstLetter(PROJECT)} Role</h3>
        <p>This {PROJECT} role will be automatically assigned to the user when their account is created.</p>
        <br />
        <Select
          options={[
            { label: capitaliseFirstLetter(ProjectRoles.MEMBER), value: ProjectRoles.MEMBER },
            { label: 'Builder', value: ProjectRoles.ADMIN },
            { label: capitaliseFirstLetter(ProjectRoles.OWNER), value: ProjectRoles.OWNER }
          ]}
          optionsSelected={[projectRole]}
          onOptionClick={(option: string) => {
            setProjectRole(option)
          }}
        />
      </div>

      {tagTablesWithCells.length > 0 ? (
        <div className="mt-4">
          <h3 className="mt-20px mb-20px">Add Permission Tags</h3>
          <p>These tags will be automatically to the user when their account is created.</p>
          <br />

          <div className="flex flex-column w-full">
            {tagTablesWithCells.map((tagTable) => {
              const tagOptions = tagTable.cells.map((cell) => ({
                label: cell.value,
                value: cell.id,
                group: cell.column.name
              }))
              tagOptions.sort((a, b) => (a.label < b.label ? -1 : 1))
              const tableSelectedTags = selectedTags[tagTable.publicId] ? selectedTags[tagTable.publicId] : []
              const selectedOptions = tableSelectedTags.map((tag) => (tag.value ? tag.value : ''))

              return (
                <div
                  key={`${tagTable.publicId}`}
                  className="flex items-center"
                  style={{ height: '75px', paddingTop: '10px', paddingBottom: '10px' }}
                >
                  <div className="flex items-center font-bold h-full" style={{ minWidth: '200px' }}>
                    {tagTable.name}
                  </div>
                  <div className="w-full">
                    <Select
                      multiselect={true}
                      options={tagOptions}
                      optionsSelected={selectedOptions}
                      setOptionsSelected={(options) => {
                        const newTags: OptionProps[] = []
                        options.map((option) => {
                          const value = tagOptions.find((tag) => tag.value === option)
                          if (value !== undefined) {
                            newTags.push(value)
                          }
                        })
                        setSelectedTags({ ...selectedTags, [tagTable.publicId]: newTags })
                      }}
                      onOptionClick={(option) => {
                        const value = tagOptions.find((tag) => tag.value === option)
                        const index = selectedTags[tagTable.publicId]
                          ? selectedTags[tagTable.publicId].findIndex((tag) => tag.value === option)
                          : -1
                        const isSelected = index !== -1 ? true : false

                        if (!isSelected) {
                          if (value) {
                            const newTags = [...tableSelectedTags, value]
                            setSelectedTags({ ...selectedTags, [tagTable.publicId]: newTags })
                          }
                        } else {
                          if (value) {
                            tableSelectedTags.splice(index, 1)
                            setSelectedTags({ ...selectedTags, [tagTable.publicId]: tableSelectedTags })
                          }
                        }
                      }}
                      groupBy={true}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <div className="flex items-center">
        <Button
          internalType="accept"
          className="text-base"
          style={{ marginTop: '20px', marginLeft: 'auto' }}
          onClick={() => handleClick()}
        >
          {invite ? 'Update' : '+ Send Invites'}
        </Button>
      </div>
    </div>
  )
}

export default InviteUserForm
